import { KeyboardArrowDown } from '@mui/icons-material';
import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';

export type TexFieldProps = {
  label: string;
  name: string;
  value?: string | number;
  onHandleChange?: (event: SelectChangeEvent<number | string>) => void;
  options: {
    label: string | number | React.ReactNode;
    value: string | number | string[];
  }[];
  placeholder?: string;
  multiple?: boolean;
};

export default function SelectInput({
  label,
  name,
  value,
  onHandleChange,
  options,
  placeholder,
  multiple = false,
}: TexFieldProps) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      <p className="text-text-field font-gilroy text-label  mb-2 capitalize">{label}</p>
      <Select
        notched={false}
        className="w-full"
        autoComplete="off"
        name={name}
        defaultValue={value}
        multiple={multiple}
        value={value}
        IconComponent={KeyboardArrowDown}
        classes={{
          icon: '!text-select-arrow',
        }}
        input={
          <OutlinedInput
            notched={false}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            classes={{
              root: 'w-full',
              notchedOutline: clsx({
                'rounded-md': true,
                '!border-field-border': !focused,
                '!border-blue': focused,
              }),
              input: '!text-normal !text-grey !pl-5.75 !pt-4 !pb-2.75  !font-gilroy',
            }}
          />
        }
        onChange={onHandleChange}
      >
        <MenuItem className="!text-normal !font-gilroy" disabled value=" ">
          {placeholder || 'Placeholder'}
        </MenuItem>
        {options.map(({ label, value }, index) => (
          <MenuItem className="!text-normal !font-gilroy !text-grey" key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
