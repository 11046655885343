import React from 'react';
// import { ReactComponent as DashboardIcon } from "../../Assets/Images/Chart_fill.svg";
import { ReactComponent as GraphIcon } from '../../Assets/Images/Graph.svg';
// import { ReactComponent as UserIcon } from "../../Assets/Images/User.svg";
// import { ReactComponent as SettingIcon } from "../../Assets/Images/Setting.svg";
import personImageUrl from '../../Assets/Images/person.jpg';
import NavItem, { INavItem } from '../NavItem';
import { useEffect, useState } from 'react';
import { AppBar, Avatar, IconButton } from '@mui/material';

import ProfileMenu from '../ProfileMenu';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getAuthToken } from '../../client';
import { verifyAuthTokenApi } from '../../rest_client';
import {
  ArrowBack,
  OnlinePrediction as OnlinePredictionIcon,
  OfflineBolt as OfflineBoltIcon,
  Radio as RadioIcon,
  Assignment as AssignmentIcon,
  DisplaySettings as DisplaySettingsIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { TvIcon } from '@heroicons/react/20/solid';

export type DashboardWrapperProps = {
  children?: React.ReactNode;
};

export const PermissionContext = React.createContext<Set<string>>(new Set());

export default function DashboardWrapper({ children }: DashboardWrapperProps) {
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authorizationToken = getAuthToken();
    if (!authorizationToken) {
      navigate('/login');
    } else {
      verifyAuthTokenApi({ token: authorizationToken })
        .then(response => {
          if (!response) {
            navigate('/login');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [navigate, pathname]);

  const handleBack = () => {
    navigate(-1);
  };

  const mainMenuItems: INavItem[] = [];
  const manageItems: INavItem[] = [];
  //const generalItems: INavItem[] = [];

  /*
   {
        path: "televisions",
        element: <ComingSoon />,
      },
      {
        path: "online-media-sources",
        element: <ComingSoon />,
      },
      {
        path: "offline-media-sources",
        element: <ComingSoon />,
      },

  */

  mainMenuItems.push({
    Icon: <RadioIcon className="text-white" />,
    label: 'Radios',
    link: '/radios',
  });

  mainMenuItems.push({
    Icon: <SearchIcon className="text-white" />,
    label: 'Search',
    link: '/search',
  });

  mainMenuItems.push({
    Icon: <TvIcon className="text-white w-5 h-5" />,
    label: 'Televisions',
    link: '/televisions',
  });

  mainMenuItems.push({
    Icon: <OnlinePredictionIcon className="text-white" />,
    label: 'Online Media Sources',
    link: '/online-media-sources',
  });

  mainMenuItems.push({
    Icon: <OfflineBoltIcon className="text-white" />,
    label: 'Offline Media Sources',
    link: '/offline-media-sources',
  });

  manageItems.push({
    Icon: <AssignmentIcon className="text-white" />,
    label: 'Monotoring Tasks',
    link: '/jobs',
  });

  manageItems.push({
    Icon: <DisplaySettingsIcon className="text-white" />,
    label: 'Playground',
    link: '/playground',
  });

  /*
  if (canViewAccountsPage) {
    manageItems.push({
      Icon: <UserIcon />,
      label: "accounts",
    });
  }
  if (canViewReportsPage) {
    manageItems.push({
      Icon: <DashboardIcon />,
      label: "reports",
    });
  }*/
  // generalItems.push({
  //   Icon: <ShieldIcon />,
  //   label: "Admin Panel",
  //   link: "/admin-panel/roles",
  // });

  /*
  if (canViewSettingsPage) {
    generalItems.push({
      Icon: <SettingIcon />,
      label: "Settings",
    });
  }*/

  function onHandleMenuSelected(label: string) {
    setSelectedMenu(label);
  }

  return (
    <div className="flex flex-row box-border min-h-screen w-full h-full overflow-hidden">
      <div className="flex flex-col items-start box-border w-65 bg-sider pt-6 pl-4">
        <div className="flex flex-row justify-start items-center gap-3.5 mb-15.25 pl-3.5">
          <h2 className="text-white font-gilroy font-extrabold text-h3-small">Sauti Safi</h2>
        </div>

        <div className="flex w-full flex-col mb-12.45">
          <h3 className="text-light-blue text-normal uppercase font-medium font-gilroy mb-1.5 pl-3.5">
            main menu
          </h3>
          {mainMenuItems.map((item, index) => (
            <NavItem
              {...{
                ...item,
                key: index,
                id: index,
                onSelectMenu: onHandleMenuSelected,
                selectedMenu: selectedMenu,
              }}
            />
          ))}
        </div>

        <div className="flex w-full flex-col mb-12.45">
          <h3 className="text-light-blue text-normal capitalize font-medium font-gilroy mb-1.5 pl-3.5">
            Manage
          </h3>
          {manageItems.map((item, index) => (
            <NavItem
              {...{
                ...item,
                key: index,
                id: index,
                onSelectMenu: onHandleMenuSelected,
                selectedMenu: selectedMenu,
              }}
            />
          ))}
        </div>

        {/* <div className="flex w-full flex-col">
            <h3 className="text-light-blue text-normal capitalize font-medium font-gilroy mb-1.5 pl-3.5">
              General
            </h3>
            {generalItems.map((item, index) => (
              <NavItem
                {...{
                  ...item,
                  key: index,
                  id: index,
                  onSelectMenu: onHandleMenuSelected,
                  selectedMenu: selectedMenu,
                }}
              />
            ))}
          </div> */}
      </div>

      <div className="flex flex-col flex-1 relative">
        <AppBar
          position="absolute"
          className="flex !box-border !bg-white h-22.5 pr-9.75 pl-13.5 !shadow-app-bar"
          classes={{
            root: '!border-b !border-solid !border-light-border !shadow-app-bar',
          }}
        >
          <div className="flex flex-row items-center justify-between  w-full  flex-1 ">
            <div className="flex items-center w-[30%]">
              <IconButton onClick={handleBack}>
                <ArrowBack />
              </IconButton>
            </div>
            <div className="flex flex-row items-center gap-3.75">
              <div className="flex flex-row items-center gap-5">
                <Avatar
                  className="!bg-avatar !w-10 !h-10"
                  src={personImageUrl}
                  alt="Person Profile"
                />
              </div>
              <div className="flex">
                <ProfileMenu />
              </div>
            </div>
          </div>
        </AppBar>
        <div className="relative box-border  flex flex-col  overflow-y-auto top-22.5">
          <PermissionContext.Provider value={new Set()}>
            <Outlet />
          </PermissionContext.Provider>
        </div>
      </div>
    </div>
  );
}
