import { OutlinedInput } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import clsx from 'clsx';

export type IOutlinedInput = {
  children?: any;
  label: string;
  type?: string;
  onHandleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export default function OutlineInput({ label, type, onHandleChange }: IOutlinedInput) {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <div className="w-full">
      <p className="text-label-text font-gilroy text-label mb-2.5">{label}</p>
      <OutlinedInput
        notched={false}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        type={type || 'text'}
        onChange={event => onHandleChange(event)}
        classes={{
          root: 'w-full w-100',
          notchedOutline: clsx({
            'rounded-sm': true,
            '!border-brown-light': !focused,
            '!border-blue': focused,
          }),
          input: '!text-input-field !text-grey !px-6  !py-4.5 !font-gilroy',
        }}
      />
    </div>
  );
}
