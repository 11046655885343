import ModuleWrapper from '../../Components/ModuleWrapper';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { CircularProgress, Paper } from '@mui/material';
import SearchInput from '../../Components/SearchInput';
import { useSearch } from './use-search';
import { SearchOff } from '@mui/icons-material';

export default function SearchPage() {
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const { loading, results, isErrored, fetchSearchResults } = useSearch();
  const handleInput = debounce((value: string) => {
    setCurrentSearch(value);
  }, 500);

  useEffect(() => {
    if (currentSearch.length === 0) return;
    fetchSearchResults(currentSearch);
  }, [currentSearch]);

  console.log(results, loading, isErrored);
  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">Search</h2>
      <div className="flex w-full mt-6.5 pb-4 ">
        <Paper className="w-full flex flex-col items-center !px-[10%]  !shadow-paper !rounded-sm !py-7.5 min-h-[600px] gap-6">
          <div className="w-full border border-gray-200 pb-2 pt-4 rounded-lg">
            <SearchInput label="Search" onChange={value => handleInput(value)} />
          </div>

          {currentSearch && (
            <div className="w-full flex flex-col justify-center items-center border border-gray-200 pb-2 pt-8 rounded-sm mt-2 min-h-[200px] overflow-y-auto">
              {loading ? (
                <CircularProgress
                  size={23}
                  classes={{
                    root: '!text-black',
                  }}
                />
              ) : (
                <div className="flex flex-col gap-2 items-start w-full max-h-[calc(100vh-350px)] overflow-auto">
                  {results.length > 0 &&
                    results.map((result, index) => (
                      <p
                        key={index}
                        className="text-gray-500 px-4 hover:bg-gray-50 font-medium text-sm"
                      >
                        <span>{result.pre} </span>
                        <span className="text-blue font-bold ">{result.before}</span>
                        <span> </span>
                        <span>{result.after}</span>
                        <span>....</span>
                      </p>
                    ))}
                  {results.length == 0 && (
                    <div className="w-full flex flex-col justify-center items-center gap-2">
                      <SearchOff className="!w-8 !h-8 text-light-blue" />
                      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
                        No Results Found
                      </h2>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Paper>
      </div>
    </ModuleWrapper>
  );
}
