import { Checkbox } from '@mui/material';
import { ChangeEvent } from 'react';
import clsx from 'clsx';

export type CheckBoxProps = {
  checked?: boolean;
  onHandleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export default function CheckBox({ checked, onHandleChange }: CheckBoxProps) {
  return (
    <Checkbox
      classes={{
        root: clsx({
          'w-5 h-5 border-1': true,
          '!text-brown-light': !checked,
          '!text-blue': checked,
        }),
      }}
      checked={checked}
      onChange={onHandleChange}
    />
  );
}
