import { Button } from '@mui/material';
import clsx from 'clsx';

export type StatusButtonProps = {
  label: string;
  active: boolean;
};

export default function StatusButton({ label, active }: StatusButtonProps) {
  return (
    <Button
      className={clsx(
        '!w-28.5 !h-8.25 !px-2 !py-1 !font-gilroy !font-medium !text-normal !rounded-xlg !capitalize ',
        {
          '!text-active !bg-active/10': active,
          '!text-blocked !bg-blocked/10': !active,
        }
      )}
    >
      {label}
    </Button>
  );
}
