import ModuleWrapper from '../../Components/ModuleWrapper';
import ComingSoon from '../ComingSoon';

export default function OnlineMediaSources() {
  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">Online Media Sources</h2>
      <div className="flex w-full mt-6.5 pb-4">
        <ComingSoon header="Online Media Sources" />
      </div>
    </ModuleWrapper>
  );
}
