import { InputAdornment, OutlinedInput } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../Assets/Images/Search.svg';

export type ISearchInput = {
  children?: any;
  label: string;
  type?: string;
  onChange?: (e: string) => void;
};

export default function SearchInput({ label, onChange }: ISearchInput) {
  return (
    <OutlinedInput
      notched={false}
      classes={{
        root: 'w-full',
        notchedOutline: clsx({
          'rounded-0 !border-0': true,
        }),
        input: '!text-normal !text-placeholder   !py-0 !px-0 !pl-3 !font-gilroy',
      }}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon className="mt-[-4px]" />
        </InputAdornment>
      }
      placeholder={label}
      onChange={e => {
        onChange && onChange(e.target.value);
      }}
    />
  );
}
