import { Paper, Tab, Tabs } from '@mui/material';
import React from 'react';

export interface ITab {
  name: string;
}
export type TabContainerProps = {
  tabs: ITab[];
  onTabChange: (index: number) => void;
  activeTab: number;
  isSmall?: boolean;
};

export default function TabContainer({ tabs, onTabChange, activeTab, isSmall }: TabContainerProps) {
  function onHandleChange(event: React.SyntheticEvent, newValue: number) {
    onTabChange(newValue);
  }

  return (
    <Paper className="!shadow-paper !rounded-lgm !px-1.75 !pt-1.5 !pb-1.75 !w-auto ">
      <Tabs
        className="!gap-6.5"
        value={activeTab}
        onChange={onHandleChange}
        classes={{
          indicator: '!hidden',
        }}
      >
        {tabs.map(({ name }, index) => (
          <Tab
            classes={{
              root: '!font-gilroy !text-input-field !text-xs font-medium text-header !capitalize !py-3.25 !px-12.25',
              selected: '!border-b-0 !bg-blue !text-white !rounded-xs !font-semibold',
            }}
            label={name}
            key={index}
            id={`tab-${index}`}
          />
        ))}
      </Tabs>
    </Paper>
  );
}
