import { IconButton } from '@mui/material';
import { ReactComponent as CheckedBox } from '../../Assets/Images/checked-checkbox.svg';
import { ReactComponent as UncheckedBox } from '../../Assets/Images/unchecked-checkbox.svg';

export type StyledCheckBoxProps = {
  checked?: boolean;
  onHandleChange?: (checked: boolean) => void;
};

export default function StyledCheckBox({ checked, onHandleChange }: StyledCheckBoxProps) {
  return (
    <IconButton onClick={() => onHandleChange && onHandleChange(!checked)}>
      {checked ? <CheckedBox /> : <UncheckedBox />}
    </IconButton>
  );
}
