import ModuleWrapper from '../../Components/ModuleWrapper';
import DataTable, { ITableColumn } from '../../Components/DataTable';
import StatusButton from '../../Components/StatusButton';
import ActionList from '../../Components/ActionList';
import { toYYMMdd } from '../../utils/date';
import { useFetchRadios } from './use-fetch-radios';
import { useEffect } from 'react';

const ActionDialog = () => {
  return (
    <ActionList>
      <h1>Item</h1>
    </ActionList>
  );
};
export default function Radios() {
  const { loading, fetchRadios, radios, totalItems, totalPages, page, setPage, limit } =
    useFetchRadios();

  useEffect(() => {
    fetchRadios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const tableColumns: ITableColumn[] = [
    {
      name: 'Date Created',
      dataKey: 'created_at',
    },
    {
      name: 'Radio',
      dataKey: 'name',
    },
    {
      name: 'Number of Monitoring Tasks',
      dataKey: 'job_count',
    },
    // {
    //   name: "Action",
    //   dataKey: "action",
    // },
  ];

  const tableData = radios.map((item: any, index: number) => ({
    ...item,
    action: <ActionDialog />,
    created_at: toYYMMdd(item.created_at),
    status: <StatusButton label={item?.isActive ? 'active' : 'blocked'} active={item?.isActive} />,
  }));

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">Radios</h2>
      <div className="flex w-full mt-6.5 pb-4">
        <DataTable
          columns={tableColumns}
          data={tableData}
          header="Radios List"
          subheader="List of all radios"
          loading={loading}
          {...{
            totalItems,
            totalPages,
            page,
            setPage: (page: number) => {
              setPage(page);
            },
            limit,
          }}
        />
      </div>
    </ModuleWrapper>
  );
}
