import { useCallback, useState } from 'react';
import { usePagination } from '../../Common/hooks/use-pagination';
import { fetchJobsApi, fetchJobExecutionsApi } from '../../rest_client';
import { useParams } from 'react-router-dom';
import { toYYMMdd, toYYMMddHHmmss } from '../../utils/date';

export type IJob = {
  id: string;
  title: string;
  job_type: string;
  description: string;
  source_system: string;
  start_time: string;
  end_time: string;
  frequency: string;
  job_count: number;
  executions?: any[];
};

export type IExecutionItem = {
  date: string;
  currentStep: string;
  executions: any[];
};

export function useFetchJobExecutions() {
  const [loading, setLoading] = useState<boolean>(false);
  const [executionItems, setExecutionItems] = useState<IExecutionItem[]>([]);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const { setTotalItems, setTotalPages, totalItems, totalPages, page, limit, setPage } =
    usePagination();

  const jobId = useParams<{ jobId: string }>()?.jobId;

  const fetchExecutions = useCallback(async () => {
    try {
      if (!jobId) return;
      setLoading(true);
      const response = await fetchJobExecutionsApi({
        limit: limit,
        offset: page - 1,
        jobId,
      });
      setLoading(false);
      if (response) {
        setTotalItems(response?.count ?? 0);
        setTotalPages(response?.count ? Math.ceil(response.count / limit) : 0);
        const executions = response?.results ?? [];
        const combineSteps: {
          [key: string]: any;
        } = {};
        executions?.forEach((execution: any) => {
          const job = execution?.job;
          if (job) {
            combineSteps[job] = combineSteps[job] ? [...combineSteps[job], execution] : [execution];
          }
        });

        const executionItems: IExecutionItem[] = [];

        Object.keys(combineSteps).forEach(key => {
          const currentStep = combineSteps[key][0]?.status?.replace(/_/g, ' ');
          executionItems.push({
            date: combineSteps[key][0]?.created_at
              ? (toYYMMddHHmmss(combineSteps[key][0].created_at) as string)
              : ' ',
            currentStep,
            executions: combineSteps[key],
          });
        });
        setExecutionItems(executionItems);
      } else {
        setIsErrored(true);
      }
    } catch (error) {
      setLoading(false);
      setIsErrored(true);
      console.log(error);
    }
  }, [limit, page, setTotalItems, setTotalPages, jobId]);

  return {
    fetchExecutions,
    loading,
    executionItems,
    isErrored,
    totalItems,
    totalPages,
    page,
    setPage,
    limit,
  };
}
