import { useCallback, useState } from 'react';
import { searchReportsApi } from '../../rest_client';
import { before } from 'lodash';
import { key } from 'localforage';

export type ISearchResult = {
  transcript: string;
  audioFile: string;
  summary: string;
  named_entities: string;
  before: string;
  after: string;
  pre: string;
};

export function useSearch() {
  const [loading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<ISearchResult[]>([]);
  const [isErrored, setIsErrored] = useState<boolean>(false);

  const formatKeywords = (text: string, keyword: string) => {
    if (text.length === 0 || keyword.length === 0) {
      return {
        pre: '',
        before: '',
        after: '',
      };
    }
    const lowerCaseText = text.toLowerCase();
    const lowerCaseKeyword = keyword.toLowerCase();
    const index = lowerCaseText.indexOf(lowerCaseKeyword);
    if (index === -1) {
      return {
        pre: '',
        before: '',
        after: '',
      };
    }

    const pre = index < 200 ? text.slice(0, index) : text.slice(index - 200, index);

    const before = text.slice(index, keyword.length + index);
    const after = text.slice(keyword.length + index, keyword.length + index + 400);
    return {
      before,
      after,
      pre,
    };
  };

  const fetchSearchResults = useCallback(async (query: string) => {
    try {
      setLoading(true);
      const response = await searchReportsApi({
        query,
      });
      const data = (response || []).map((item: any) => {
        const { before, after, pre } = formatKeywords(item.transcript, query);
        return {
          transcript: item.transcript,
          audioFile: item.audio_file_path,
          summary: item.summary,
          named_entities: item.named_entities,
          before,
          after,
          pre,
        };
      });

      setLoading(false);
      if (response) {
        setResults(data);
      } else {
        setIsErrored(true);
      }
    } catch (error) {
      setLoading(false);
      setIsErrored(true);
      console.log(error);
    }
  }, []);

  return {
    loading,
    results,
    isErrored,
    fetchSearchResults,
  };
}
