import { ChangeEvent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CREATE_ROLE,
  GET_ALL_PERMISSIONS,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
  client,
} from '../../client';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ModalForm from '../../Components/ModalForm';
import AlertBox from '../../Components/AlertBox';
import TextField from '../../Components/TextField';
import StyledCheckBox from './StyledCheckBox';

export interface IPermission {
  id: number;
  description: string;
}

export default function RoleForm() {
  const [open, setOpen] = useState<boolean>(true);
  const [roleDetails, setRoleDetails] = useState<{
    role: string;
  }>({
    role: '',
  });
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [grants, setGrants] = useState<Set<number>>(new Set());
  const [errors, setErrors] = useState<string[]>([]);
  const { pathname } = useLocation();

  const [createRole, { loading: createLoading }] = useMutation(CREATE_ROLE, {
    errorPolicy: 'all',
  });

  const [updateRole, { loading: updateLoading }] = useMutation(UPDATE_ROLE, {
    errorPolicy: 'all',
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const createRolePath = '/admin-panel/roles/create';
  const updateRolePath = '/admin-panel/roles/edit';
  const rolesPath = '/admin-panel/roles';

  const isCreatePath = matchPath(createRolePath, pathname);

  const roleId = searchParams.get('roleId');
  const isUpdatePath = matchPath(updateRolePath, pathname);

  useEffect(() => {
    const isUpdatePath = matchPath(updateRolePath, pathname);
    client
      .query({
        query: GET_ALL_PERMISSIONS,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .then(result => {
        if (result.errors) {
          console.log(result.errors);
        }
        if (result.data) {
          const permissions = result?.data?.getAllPermissions?.permissions || [];
          setPermissions(permissions);
        }
      });
    if (isUpdatePath) {
      if (roleId) {
        client
          .query({
            query: GET_ROLE_BY_ID(parseInt(roleId)),
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          })
          .then(result => {
            if (result.errors) {
              console.log(result.errors);
              navigate(createRolePath);
            }
            if (result.data) {
              const roleName = result?.data?.getRoleById?.name;
              const permissions = (result?.data?.getRoleById?.permissions || []).map(
                (permission: any) => permission.id
              );
              setRoleDetails(roleDetails => ({
                ...roleDetails,
                role: roleName,
              }));
              setGrants(new Set(permissions));
            }
          });
      }
    }
  }, [navigate, roleId, pathname]);

  function onHandleClose() {
    setOpen(false);
    navigate(rolesPath);
  }
  function onSubmit() {
    if (isCreatePath) {
      createRole({
        variables: {
          createRoleInput: {
            name: roleDetails.role,
            permissions: Array.from(grants),
          },
        },
      }).then(result => {
        if (result.errors) {
          console.log(result.errors);
          setErrors(errors => [...errors, 'The request has failed']);
        }
        if (result.data) {
          navigate(rolesPath);
        }
      });
    }

    if (isUpdatePath && roleId) {
      updateRole({
        variables: {
          roleInput: {
            name: roleDetails.role,
            permissions: Array.from(grants),
            roleId: parseInt(roleId),
          },
        },
      }).then(result => {
        if (result.errors) {
          console.log(result.errors);
          setErrors(errors => [...errors, 'The request has failed']);
        }
        if (result.data) {
          navigate(rolesPath);
        }
      });
    }
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setRoleDetails(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }

  function onPermissionUpdate(status: boolean, id: number) {
    const items = new Set(grants);
    if (status) {
      items.add(id);
    } else {
      items.delete(id);
    }
    setGrants(items);
  }

  return (
    <ModalForm
      open={open}
      onHandleClose={onHandleClose}
      onHandleSubmit={onSubmit}
      loading={createLoading || updateLoading}
      isTop={true}
    >
      <h3 className="font-gilroy font-bold text-form-header text-h4 mb-4.75">
        {isUpdatePath ? 'Edit' : 'Add'} Role
      </h3>
      <div className="flex flex-col w-full gap-5">
        <div className="flex justify-start w-7/12 ">
          <TextField
            name="role"
            label="Role Name"
            onHandleChange={onInputChange}
            value={roleDetails.role}
          />
        </div>
        <div className="flex flex-col flex-1">
          <h3 className="text-base font-medium text-text-field mb-4">
            {isUpdatePath ? 'Edit' : 'Add'} Permissions
          </h3>
          <div className="flex flex-col max-h-60 overflow-y-auto gap-4.25">
            {permissions.map(permission => (
              <div key={permission.id} className="flex flex-row items-center gap-4.25">
                <StyledCheckBox
                  checked={grants.has(permission.id)}
                  onHandleChange={checked => onPermissionUpdate(checked, permission.id)}
                />
                <p className="text-sm font-normal text-placeholder first-letter:capitalize">
                  {permission.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        {errors.map((error, index) => (
          <AlertBox message={error} isOpen={true} mode="error" key={index} duration={6000} />
        ))}
      </div>
    </ModalForm>
  );
}
