import ModuleWrapper from '../../Components/ModuleWrapper';
import DataTable, { ITableColumn } from '../../Components/DataTable';
import StatusButton from '../../Components/StatusButton';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useFetchJobs } from './use-fetch-jobs';
import { debounce, IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as DotsIcons } from '../../Assets/Images/Dots.svg';
import { reRunJobApi } from '../../rest_client';
import TextField from '../../Components/TextField';
import DatePickerInput from '../../Components/DatePickerInput';
import { Dayjs } from 'dayjs';
import { Restore } from '@mui/icons-material';

function ActionList({ jobId }: { jobId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = useCallback(
    (jobId: string) => {
      navigate(`/jobs/${jobId}`);
    },
    [navigate, jobId]
  );

  const reRunJob = useCallback(() => {
    reRunJobApi({ jobId })
      .then(res => {
        console.log(res);
        navigate(`/jobs/${jobId}`);
      })
      .catch(err => {
        console.log(err);
        navigate(`/jobs/${jobId}`);
      });
  }, [jobId]);

  return (
    <div className="inline-flex">
      <IconButton onClick={handleClick}>
        <DotsIcons />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: '!rounded-sm !shadow-dialog',
        }}
      >
        <div className="w-57.5 !h-48.5 !py-6">
          <MenuItem
            onClick={() => handleNavigate(jobId)}
            className="!text-normal !font-gilroy !text-grey"
          >
            View Execution
          </MenuItem>
          <MenuItem onClick={() => reRunJob()} className="!text-normal !font-gilroy !text-grey">
            Rerun Task
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
}

export default function Jobs() {
  const navigate = useNavigate();
  const {
    fetchJobs,
    loading,
    jobs,
    totalItems,
    totalPages,
    page,
    setPage,
    limit,
    setEndDate,
    setStartDate,
    setTitle,
  } = useFetchJobs();

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  function onClickCreateButton() {
    navigate('/create-job');
  }
  const tableColumns: ITableColumn[] = [
    {
      name: 'Title',
      dataKey: 'title',
    },
    {
      name: 'Type',
      dataKey: 'job_type',
    },
    {
      name: 'Source',
      dataKey: 'source_system',
    },
    // {
    //   name: "Number of Executions",
    //   dataKey: "job_count",
    // },
    {
      name: 'Start Time',
      dataKey: 'start_time',
    },
    {
      name: 'End Time',
      dataKey: 'end_time',
    },
    {
      name: 'Frequency',
      dataKey: 'frequency',
    },

    {
      name: 'Action',
      dataKey: 'action',
    },
  ];

  const tableData = jobs.map((item: any, index: number) => ({
    ...item,
    action: <ActionList {...{ jobId: item.id }} />,
    status: <StatusButton label={item?.isActive ? 'active' : 'blocked'} active={item?.isActive} />,
  }));

  const onFilterValuesChange = useCallback(
    ({
      title,
      startDate,
      endDate,
    }: {
      title: string;
      startDate: Dayjs | null;
      endDate: Dayjs | null;
    }) => {
      setEndDate(endDate);
      setStartDate(startDate);
      setTitle(title);
    },
    [setEndDate, setStartDate, setTitle]
  );

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">Monitoring Tasks</h2>
      <div className="flex w-full mt-6.5 pb-4">
        <DataTable
          columns={tableColumns}
          data={tableData}
          header="Monitoring Tasks"
          onClickCreateButton={onClickCreateButton}
          createButtonText="Add Monitoring Task"
          loading={loading}
          {...{
            totalItems,
            totalPages,
            page,
            setPage: (page: number) => {
              setPage(page);
            },
            limit,
          }}
          filterComponent={<FilterComponent onChangeFilters={onFilterValuesChange} />}
        />
      </div>
    </ModuleWrapper>
  );
}
const FilterComponent = ({
  onChangeFilters,
}: {
  onChangeFilters(filters: { title: string; startDate: Dayjs | null; endDate: Dayjs | null }): void;
}) => {
  const [title, setTitle] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const debouncedSetTitle = debounce(setTitle, 100);

  const onValuesChange = useCallback(() => {
    onChangeFilters({ title, startDate, endDate });
  }, [title, startDate, endDate]);

  useEffect(() => {
    onValuesChange();
  }, [title, startDate, endDate, onValuesChange]);

  const handleReset = useCallback(() => {
    setTitle('');
    setStartDate(null);
    setEndDate(null);
  }, []);

  return (
    <div className="flex flex-row gap-5 items-center justify-start">
      <TextField
        name="title"
        label="Title"
        value={title}
        onHandleChange={e => {
          debouncedSetTitle(e.target.value);
        }}
      />
      <DatePickerInput
        label="Start Date"
        value={startDate}
        onHandleChange={date => {
          setStartDate(date);
        }}
      />

      <DatePickerInput
        label="End Date"
        value={endDate}
        onHandleChange={date => {
          setEndDate(date);
        }}
        minDate={startDate}
      />
      <IconButton onClick={handleReset} sx={{ mt: 3 }}>
        <Restore />
      </IconButton>
    </div>
  );
};
