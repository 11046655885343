import axios from 'axios';
import { getAuthToken } from './client';
import qs from 'qs';

const httpInstance = axios.create({
  baseURL: 'https://schedule.mwendospeech.com/v1',
});

const httpInstanceV2 = axios.create({
  baseURL: 'https://schedule.mwendospeech.com/v2',
});

const httpInstanceV3 = axios.create({
  baseURL: 'https://schedule.mwendospeech.com/v3',
});

const backendInstance = axios.create({
  baseURL: 'https://backend.mwendospeech.com',
});

export type FileUploadInput = {
  name: string;
  file: File;
};

export async function uploadFileToStorage(files: FileUploadInput[]) {
  const formData = new FormData();
  for (const { name, file } of files) {
    formData.append(name, file);
  }

  const response = await httpInstance({
    url: '/upload',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response?.data;
}

export async function authenticateUserApi({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  const response = await httpInstance({
    url: '/auth/token/',
    method: 'POST',
    data: {
      username,
      password,
    },
  });
  return response?.data;
}

export async function verifyAuthTokenApi({ token }: { token: string }) {
  const response = await httpInstance({
    url: '/auth/token/verify/',
    method: 'POST',
    data: { token },
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return response.data;
}

export async function getRadioListApi({ limit, offset }: { limit: number; offset: number }) {
  const response = await httpInstance({
    url: '/media/list/',
    method: 'GET',
    params: { limit, offset },
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return response?.data || {};
}

export async function fetchJobsApi({
  limit,
  offset,
  startDate,
  endDate,
  title,
  action,
}: {
  limit: number;
  offset: number;
  startDate?: string;
  endDate?: string;
  title?: string;
  action?: string;
}) {
  const response = await httpInstanceV2({
    url: '/jobs/list/',
    method: 'GET',
    params: {
      limit,
      offset,
      start_date: startDate,
      end_date: endDate,
      title,
      action,
    },
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return response?.data || {};
}

export async function createJobApi(payload: any) {
  const response = await httpInstance({
    url: '/jobs/create/',
    method: 'POST',
    data: payload,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return response?.data || {};
}

export async function transcribeAPI(
  formData: FormData
): Promise<{ results: { transcript: string; file_name: string }[] }> {
  const response = await backendInstance({
    url: '/transcribe/test',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export async function complianceAPI({
  transcription,
  source,
}: {
  transcription: string;
  source?: string;
}): Promise<any> {
  const urlEncodedData = qs.stringify({
    transcription,
    source,
  });

  const response = await backendInstance.post('/check-compliance-test', urlEncodedData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
}

export async function reRunJobApi({ jobId }: { jobId: string }) {
  const response = await httpInstance({
    url: `/jobs/re-run/job/`,
    method: 'POST',
    data: { job_uid: jobId },
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return response?.data || {};
}

export async function fetchJobExecutionsApi({
  limit,
  offset,
  jobId,
}: {
  limit: number;
  offset: number;
  jobId: string;
}) {
  const response = await httpInstanceV2({
    url: `/jobs/${jobId}/executions/`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return response?.data || {};
}

export async function searchReportsApi({ query }: { query: string }) {
  // Create url encoded data
  const response = await httpInstanceV3({
    url: `/search/report/${query}/`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  const data = (response?.data?.results || []).map((item: any) => ({
    transcript: item.transcript,
    audioFile: item.audio_file_path,
    summary: item.summary,
    named_entities: item.named_entities,
  }));

  return data;
}
