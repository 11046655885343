import { useState } from 'react';

export function usePagination() {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(8);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  return {
    page,
    setPage,
    limit,
    setLimit,
    totalItems,
    setTotalItems,
    totalPages,
    setTotalPages,
  };
}
