import { Button } from '@mui/material';
import { ReactComponent as AddIcon } from '../../Assets/Images/plus.svg';
import clsx from 'clsx';

export type CreateButtonProps = {
  label: string;
  onHandleClick?: () => void;
  hideIcon?: boolean;
};

export default function CreateButton({ label, onHandleClick, hideIcon }: CreateButtonProps) {
  return (
    <Button className="!bg-blue !px-6.25 !py-3.5" onClick={onHandleClick}>
      {!hideIcon && <AddIcon />}
      <span
        className={clsx('!normal-case !text-label text-white !font-gilroy !font-medium', {
          'ml-3': !hideIcon,
        })}
      >
        {label}
      </span>
    </Button>
  );
}
