import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Skeleton } from '@mui/material';
import clsx from 'clsx';
import CreateButton from '../CreateButton';
import TablePagination from '../TablePagination';

export type ITableColumn = {
  name: string;
  dataKey: string;
};

export type ITableData = {
  [key: string]: string | number | React.ReactNode;
};

export type DataTableProps = {
  header?: string;
  subheader?: string;
  columns: ITableColumn[];
  data: ITableData[];
  onClickCreateButton?: () => void;
  createButtonText?: string;
  hideHeaderSection?: boolean;
  rootClassName?: string;
  hideButtonIcon?: boolean;
  hidePagination?: boolean;
  loading?: boolean;
  totalItems?: number;
  totalPages?: number;
  page?: number;
  setPage?: (page: number) => void;
  limit?: number;
  filterComponent?: React.ReactNode;
};

export default function DataTable({
  columns,
  data,
  header,
  subheader,
  onClickCreateButton,
  createButtonText,
  hideHeaderSection,
  rootClassName,
  hideButtonIcon,
  hidePagination = false,
  loading,
  totalItems,
  totalPages,
  page,
  setPage,
  limit,
  filterComponent,
}: DataTableProps) {
  const dataKeys: string[] = columns.map(({ dataKey }: ITableColumn) => dataKey);
  return (
    <div className="flex flex-col gap-2.25 w-full flex-1">
      <Paper
        className={clsx('w-full !shadow-paper !rounded-sm !pb-7.5', {
          [rootClassName || '']: rootClassName,
        })}
      >
        <div
          className={clsx('flex flex-row pl-4 pt-4 pr-6 pb-5.25 items-start justify-between', {
            hidden: hideHeaderSection,
          })}
        >
          <div className="flex flex-col gap-1.5 ">
            {header && <h3 className="font-gilroy font-bold text-medium">{header}</h3>}
            {subheader && (
              <h6 className="font-girloy text-normal font-medium text-label-text ">{subheader}</h6>
            )}
          </div>
          {createButtonText && onClickCreateButton && (
            <div className="flex">
              <CreateButton
                label={createButtonText}
                onHandleClick={onClickCreateButton}
                hideIcon={hideButtonIcon}
              />
            </div>
          )}
        </div>
        {filterComponent && (
          <div className="flex flex-row pl-4 pt-4 pr-6 pb-5.25 items-start justify-start">
            {filterComponent}
          </div>
        )}
        <div className="flex w-full">
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                {columns.map(({ name }, index) => (
                  <TableCell
                    className={clsx(
                      '!font-gilroy !text-label !font-medium !text-placeholder !border-b-0',
                      {
                        '!border-t !border-solid !border-table-border !pt-5.25 !pb-5.25':
                          !hideHeaderSection,
                        '!pt-5.5 !pb-5': hideHeaderSection,
                      }
                    )}
                    key={index}
                  >
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                new Array(5).fill(0).map((_, index) => (
                  <TableRow key={index}>
                    {columns.map(({ name }, index) => (
                      <TableCell
                        className={clsx(
                          '!border-b-0 !font-gilroy !text-normal !text-header !font-medium pt-2.5 pb-2.75',
                          {
                            '!bg-cell': (index + 1) % 2 === 1,
                          }
                        )}
                        key={index}
                      >
                        <Skeleton variant="rectangular" height={60} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {!loading &&
                data.map((item: ITableData, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {dataKeys.map((dataKey, index) => (
                      <TableCell
                        className={clsx(
                          '!border-b-0 !font-gilroy !text-normal !text-header !font-medium pt-2.5 pb-2.75',
                          {
                            '!bg-cell': (rowIndex + 1) % 2 === 1,
                          }
                        )}
                        key={index}
                      >
                        {item[dataKey]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
      {!hidePagination && totalItems && totalItems > 0 && (
        <TablePagination
          totalEntries={totalItems}
          currentPage={page}
          totalPages={totalPages}
          limit={limit}
          setPage={setPage}
        />
      )}
    </div>
  );
}
