import { useNavigate, useSearchParams } from 'react-router-dom';
// import StepperActionButton from "../../../Components/StepperActionButton";
// import TextField from "../../../Components/TextField";
import { useCallback, useEffect, useState } from 'react';
import TextField from '../../Components/TextField';
import { useFetchRadios } from '../Radios/use-fetch-radios';
import { Controller, useForm } from 'react-hook-form';
import SelectInput from '../../Components/SelectInput';
import TextAreaInput from '../../Components/TextAreaInput';
import TimePickerUI from '../../Components/TimePicker';
import { Dayjs } from 'dayjs';
import ModuleWrapper from '../../Components/ModuleWrapper';
import { Button, CircularProgress, experimentalStyled } from '@mui/material';
import { createJobApi } from '../../rest_client';
import DatePickerInput from '../../Components/DatePickerInput';

export type ICreateJobForm = {
  data_source_url: string;
  job_type: string;
  title: string;
  description: string;
  start_time: Dayjs | null;
  end_time: Dayjs | null;
  days: string;
  one_time_job: string;
  expires_at: Dayjs | null;
};

export default function CreateJobForm() {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
  } = useForm<ICreateJobForm>();
  const { fetchRadios, radios } = useFetchRadios();
  const [mediaList, setMediaList] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data: ICreateJobForm) => {
      const payload = {
        data_source_url: data.data_source_url,
        job_type: data.job_type,
        title: data.title,
        description: data.description,
        repetition_frequency: ((data.days || []) as unknown as string[]).join(','),
        start_time: data.start_time?.format('HH:mm:ss'),
        end_time: data.end_time?.format('HH:mm:ss'),
        scheduled_time: [data.start_time?.toISOString(), data.end_time?.toISOString()],
        expires_at: data.expires_at?.toISOString(),
        one_time_job: data.one_time_job === 'yes',
      };

      try {
        await createJobApi(payload);
        navigate('/jobs');
      } catch (error) {
        console.log(error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    fetchRadios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (radios.length > 0) {
      const options = radios.map(radio => {
        return {
          value: radio.url,
          label: radio.name,
        };
      });
      setMediaList(options);
    }
  }, [radios]);

  const jobTypeOptions = [
    {
      value: 'RECORD_TRANSCRIBE',
      label: 'Record and Transcribe',
    },
  ];

  const daysOfWeek = [
    { label: 'Monday', value: 'mon' },
    { label: 'Tuesday', value: 'tue' },
    { label: 'Wednesday', value: 'wed' },
    { label: 'Thursday', value: 'thur' },
    { label: 'Friday', value: 'fri' },
    { label: 'Saturday', value: 'sat' },
    { label: 'Sunday', value: 'sun' },
  ];

  const oneTimeJobOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const disableSubmit = !isValid || isSubmitting;
  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header ">Create Job</h2>
      <form className="flex flex-col w-full gap-5 mt-6.5 pb-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-5 ">
          <Controller
            name="data_source_url"
            control={control}
            rules={{ required: 'Media is required' }}
            render={({ field }) => (
              <SelectInput
                label="Media"
                name="data_source_url"
                value={field.value}
                onHandleChange={field.onChange}
                options={mediaList}
                placeholder="Select Media"
              />
            )}
          />

          <Controller
            name="job_type"
            control={control}
            rules={{ required: 'Job Type is required' }}
            render={({ field }) => (
              <SelectInput
                label="Job Type"
                name="job_type"
                value={field.value}
                onHandleChange={field.onChange}
                options={jobTypeOptions}
                placeholder="Select Job Type"
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-5 ">
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <TextField
                name="title"
                label="Title"
                value={field.value}
                onHandleChange={field.onChange}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextAreaInput
                name="description"
                label="Description"
                value={field.value}
                onHandleChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-5 ">
          <Controller
            name="one_time_job"
            control={control}
            rules={{ required: 'One Time Job is required' }}
            render={({ field }) => (
              <SelectInput
                label="One Time Job"
                name="one_time_job"
                value={field.value}
                onHandleChange={field.onChange}
                options={oneTimeJobOptions}
                placeholder="Select One Time Job"
              />
            )}
          />

          <Controller
            name="expires_at"
            control={control}
            render={({ field }) => (
              <DatePickerInput
                label="Expires At"
                value={field.value}
                onHandleChange={field.onChange}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-5 ">
          <Controller
            name="start_time"
            rules={{ required: 'Start Time is required' }}
            control={control}
            render={({ field }) => (
              <TimePickerUI
                label="Start Time"
                value={field.value}
                onHandleChange={field.onChange}
              />
            )}
          />

          <Controller
            name="end_time"
            control={control}
            rules={{ required: 'End Time is required' }}
            render={({ field }) => (
              <TimePickerUI label="End Time" value={field.value} onHandleChange={field.onChange} />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-5 ">
          <Controller
            name="days"
            control={control}
            rules={{ required: 'Days is required' }}
            render={({ field }) => (
              <SelectInput
                label="Days"
                name="days"
                value={field.value ?? []}
                onHandleChange={field.onChange}
                options={daysOfWeek}
                multiple
              />
            )}
          />
        </div>
        <div className="flex w-full flex-row justify-end">
          <Button
            className="!w-33.75 !box-border !font-gilroy !text-white !px-5.25 !py-3.5 !capitalize !bg-blue !rounded-md !text-input-field disabled:!cursor-not-allowed disabled:!bg-gray-400"
            type="submit"
            disabled={disableSubmit}
          >
            {!isSubmitting ? (
              'Save'
            ) : (
              <CircularProgress
                size={23}
                classes={{
                  root: '!text-white',
                }}
              />
            )}
          </Button>
        </div>
      </form>
    </ModuleWrapper>
  );
}
