import { Paper } from '@mui/material';
import { WrenchScrewdriverIcon } from '@heroicons/react/20/solid';

export default function ComingSoon({ header }: { header: string }) {
  return (
    <Paper className="w-full flex flex-col items-center justify-center !shadow-paper !rounded-sm !pb-7.5 min-h-[600px] gap-6">
      <WrenchScrewdriverIcon className="w-16 h-16 text-light-blue" />
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
        We are Launching {header} Soon
      </h2>
    </Paper>
  );
}
