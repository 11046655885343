import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactComponent as CalendarIcon } from '../../Assets/Images/Calendar.svg';
import { Dayjs } from 'dayjs';

export type DatePickerInputProps = {
  label: string;
  value: Dayjs | null;
  onHandleChange: (newValue: Dayjs | null) => void;
  minDate?: Dayjs | null;
};

export default function DatePickerInput({
  label,
  value,
  onHandleChange,
  minDate,
}: DatePickerInputProps) {
  return (
    <div className="w-full">
      <p className="text-text-field font-gilroy text-label  mb-2 capitalize">{label}</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="!w-full !box-border !font-gilroy"
          slotProps={{
            textField: {
              variant: 'outlined',
              InputProps: {
                className: '!w-full !text-normal !text-grey  !h-12.5',
              },
            },
          }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          value={value}
          onChange={newValue => onHandleChange(newValue)}
          minDate={minDate}
        />
      </LocalizationProvider>
    </div>
  );
}
