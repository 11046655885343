import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const apiUrl = process.env.REACT_APP_API_URL;

console.log(process.env);

export function getAuthToken() {
  const payload = localStorage.getItem('X_AUTH_TOKEN');
  return payload ? JSON.parse(payload).accessToken : '';
}

export function removeToken() {
  localStorage.removeItem('X_AUTH_TOKEN');
}

const link = new HttpLink({ uri: `${apiUrl}/graphql` });

const setAuthorizationLink = setContext((request, previousContext) => ({
  headers: {
    ...previousContext.headers,
    Authorization: getAuthToken(),
  },
}));

export const client = new ApolloClient({
  link: setAuthorizationLink.concat(link),
  cache: new InMemoryCache(),
});

export const AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($authenticateUserInput: AuthenticateUserRequest!) {
    authenticateUser(authenticateUserInput: $authenticateUserInput) {
      accessToken
      refreshToken
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organizationInput: CreateOrganizationRequest!) {
    createOrganization(organizationInput: $organizationInput) {
      id
      name
      physicalAddress
      city
      country
    }
  }
`;

export const GET_ORGANIZATION_BY_ID = (organizationId: string) => gql`
    query GetOrganizationById {
        getOrganizationById(organizationInput: {organizationId: ${organizationId}}){
            id,
            name, 
            physicalAddress,
            city,
            country
        }
    }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($organizationInput: UpdateOrganizationRequest!) {
    updateOrganization(organizationInput: $organizationInput) {
      success
    }
  }
`;

export const CREATE_ORGANIZATION_SUPER_ADMIN = gql`
  mutation CreateOrganizationSuperAdmin($superAdminInput: CreateOrganizationSuperAdminRequest!) {
    createOrganizationSuperAdmin(superAdminInput: $superAdminInput) {
      id
    }
  }
`;

export const LIST_ORGANIZATIONS = gql`
  query ListOrganizations($listInput: ListOrganizationsInput!) {
    listOrganizations(listInput: $listInput) {
      organizations {
        id
        name
        physicalAddress
        city
        country
        branchesCount
        isActive
        createdAt
      }
    }
  }
`;

export const CREATE_BRANCH = gql`
  mutation CreateBranch($branchInput: CreateBranchRequest!) {
    createBranch(branchInput: $branchInput) {
      id
    }
  }
`;

export const GET_BRANCH_BY_ID = (branchId: number) => gql`
  query GetBranchById {
    getBranchById(branchInput: {branchId: ${branchId}}){
      id,
      name,
      physicalAddress,
      city,
      country,
      operatingCapital
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation UpdateBranch($branchInput: UpdateBranchRequest!) {
    updateBranch(branchInput: $branchInput) {
      success
    }
  }
`;

export const CREATE_BRANCH_SUPER_ADMIN = gql`
  mutation CreateBranchSuperAdmin($superAdminInput: CreateBranchSuperAdminRequest!) {
    createBranchSuperAdmin(superAdminInput: $superAdminInput) {
      success
    }
  }
`;

export const LIST_ORGANIZATION_BRANCHES = gql`
  query GetOrganizationBranches($organizationInput: GetOrganizationBranchesRequest!) {
    getOrganizationBranches(organizationInput: $organizationInput) {
      branches {
        id
        name
        physicalAddress
        city
        country
        operatingCapital
        createdAt
        isActive
      }
    }
  }
`;

export const LIST_ROLES = gql`
  query GetRoles($rolesInput: GetRolesInput!) {
    getRoles(rolesInput: $rolesInput) {
      roles {
        id
        name
      }
    }
  }
`;

export const CREATE_ORGANIZATION_USER = gql`
  mutation CreateOrganizationUser($organizationUserInput: CreateOrganizationUserRequest!) {
    createOrganizationUser(organizationUserInput: $organizationUserInput) {
      id
    }
  }
`;

export const LIST_ORGANIZATION_STAFFS = gql`
  query GetOrganizationStaffs($organizationInput: GetOrganizationStaffsInput!) {
    getOrganizationStaffs(organizationInput: $organizationInput) {
      staffs {
        id
        firstName
        middleName
        lastName
        email
        phoneNumber
        role {
          id
          name
        }
        createdAt
        isActive
      }
    }
  }
`;

export const CREATE_CUSTOMER_INFORMATION = gql`
  mutation CreateCustomerInformation(
    $customerInformation: CreateCustomerPersonalInformationInput!
  ) {
    createCustomerInformation(customerInformation: $customerInformation) {
      id
    }
  }
`;

export const GET_CUSTOMER_INFORMATION = (customerId: number) => gql`
  query GetCustomerInformation {
    getCustomerInformation(customerInput: {customerId: ${customerId}}){
      customerInformation {
        organizationId
        firstName
        middleName
        lastName
        email
        phoneNumber
        physicalAddress
        district
        city
        occupation
        isMarried
      }
    }
  }
`;

export const UPDATE_CUSTOMER_INFORMATION = gql`
  mutation UpdateCustomerInformation(
    $customerInformation: UpdateCustomerPersonalInformationInput!
  ) {
    updateCustomerInformation(customerInformation: $customerInformation) {
      success
    }
  }
`;
export enum CustomerIdentifiersType {
  proof_of_residence = 'proof_of_residence',
  personal_id = 'personal_id',
  employer_introduction_letter = 'employer_introduction_letter',
  employment_contract = 'employment_contract',
  salary_slip = 'salary_slip',
}

export const UPSERT_CUSTOMER_IDENTIFIERS = gql`
  mutation UpsertCustomerIdentifiers(
    $customerId: Int!
    $customerIdentifiers: [UpsertCustomerIdentifiersInput!]!
  ) {
    upsertCustomerIdentifiers(customerId: $customerId, customerIdentifiers: $customerIdentifiers) {
      identifiers {
        id
        type
        document
      }
    }
  }
`;

export const UPSERT_CUSTOMER_GUARANTOR = gql`
  mutation UpsertCustomerGuarantor($customerInput: UpsertCustomerGuarantorInput!) {
    upsertCustomerGuarantor(customerInput: $customerInput) {
      id
      firstName
    }
  }
`;

export const GET_ORGANIZATION_INDIVIDUAL_CUSTOMERS = gql`
  query GetOrganizationCustomers($customerInput: GetOrganizationCustomersInput!) {
    getOrganizationCustomers(customerInput: $customerInput) {
      customers {
        id
        organizationId
        firstName
        middleName
        lastName
        email
        phoneNumber
        physicalAddress
        district
        city
        occupation
        isMarried
        createdAt
      }
    }
  }
`;

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness($businessInput: CreateBusinessInput!) {
    createBusiness(businessInput: $businessInput) {
      id
    }
  }
`;

export const GET_BUSINESS_BY_ID = (businessId: number) => gql`
  query GetBusinessById {
    getBusinessById(businessInput: {businessId: ${businessId}}){
      id
      name
      tin
      physicalAddress
      district
      city
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness($businessInput: UpdateBusinessInput!) {
    updateBusiness(businessInput: $businessInput) {
      success
    }
  }
`;

export const UPSERT_BUSINESS_CONTACT = gql`
  mutation UpsertBusinessContactPerson($businessInput: UpsertBusinessContactPersonInput!) {
    upsertBusinessContactPerson(businessInput: $businessInput) {
      id
    }
  }
`;

export const GET_BUSINESS_CONTACT = (businessId: number) => gql`
  query GetBusinessContactPerson {
    getBusinessContactPerson(businessInput: {businessId: ${businessId}}){
      id
      firstName
      middleName
      lastName
      phoneNumber
      email
    }
  }
`;

export const CREATE_BUSINESS_IDENTIFIERS = gql`
  mutation CreateBusinessIdentifiers($businessId: Int!, $identifiers: [BusinessIdentifierInput!]!) {
    createBusinessIdentifiers(businessId: $businessId, identifiers: $identifiers) {
      identifiers {
        id
        document
      }
    }
  }
`;

export const UPSERT_BUSINESS_GUARANTOR = gql`
  mutation UpsertBusinessGurantor($guarantorInput: UpsertBusinessGuarantorInput!) {
    upsertBusinessGuarantor(guarantorInput: $guarantorInput) {
      id
    }
  }
`;

export const LIST_ORGANIZATION_BUSINESSES = gql`
  query GetOrganizationBusinesses($organizationInput: GetOrganizationBusinessesInput!) {
    getOrganizationBusinesses(organizationInput: $organizationInput) {
      businesses {
        id
        name
        physicalAddress
        district
        city
        createdAt
        updatedAt
        isActive
      }
    }
  }
`;

export const LIST_CUSTOMER_LOANEES = (organizationId: number) => gql`
query GetOrganizationCustomers{
  getOrganizationCustomers(customerInput: {organizationId: ${organizationId}}){
    customers {
      id
      firstName
      middleName
      lastName
   }
  }
}
`;

export const LIST_BUSINESS_LOANEES = (organizationId: number) => gql`
  query GetOrganizationBusinesses{
    getOrganizationBusinesses(organizationInput: {organizationId: ${organizationId}}){
      businesses {
        id
        name
        physicalAddress
        district
        city
        createdAt
        updatedAt
        isActive
      }
    }
  }
`;

export const CREATE_CUSTOMER_LOAN = gql`
  mutation CreateCustomerLoan($loanInput: CreateCustomerLoanInput!) {
    createCustomerLoan(loanInput: $loanInput) {
      id
    }
  }
`;

export const CREATE_BUSINESS_LOAN = gql`
  mutation CreateBusinessLoan($loanInput: CreateBusinessLoanInput!) {
    createBusinessLoan(loanInput: $loanInput) {
      id
    }
  }
`;

export const GET_CUSTOMER_DETAILS_BY_ID = (customerId: number) => gql`
  query GetCustomerDetails {
      getCustomerById(customerInput: {customerId: ${customerId}}){
        customer {
          id
          firstName
          middleName
          lastName
          phoneNumber
          email
          createdAt
        }
        organization {
          id
          name
        }
        totalLoan
        loans {
          id,
          loanType
          loanAmount
          interestType
          interestValue
          returnCycleType
          returnCycleValue
          status
        }
       
      }
  }
`;

export const GET_BUSINESS_DETAILS_BY_ID = (businessId: number) => gql`
  query GetBusinessDetailsById {
    getBusinessDetailsById(businessInput: {businessId: ${businessId}}){
      business {
        id
        name
        createdAt
      }
      organization {
        name
      }
      contactPerson {
        phoneNumber
        email
      }
      totalLoan
      loans {
        id,
        loanType
        loanAmount
        interestType
        interestValue
        returnCycleType
        returnCycleValue
        status
      }
    }
  }

`;

export const GET_CUSTOMER_LOAN_DETAILS = (loanId: number) => gql`
  query GetCustomerLoanDetails {
    getCustomerLoanDetails(loanInput: {loanId: ${loanId}}){
      loan {
        id
        createdAt
        interestType
        interestValue
        returnCycleType
        loanAmount
      }
      organization {
        name
      }
      branch {
        name
      }
      customer {
        firstName
        middleName
        lastName
        email
        phoneNumber
      }
    }
  }
`;

export const GET_BUSINESS_LOAN_DETAILS = (loanId: number) => gql`
  query GetBusinessLoanDetails {
    getBusinessLoanDetails(loanInput:{loanId: ${loanId}}){
      loan {
        id
        createdAt
        interestType
        interestValue
        returnCycleType
        loanAmount
      }
      organization {
        name
      }
      branch {
        name
      }
      contactPerson {
        email
        phoneNumber
      }
      business {
        name
      }
      
    }
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query GetUserPermissions {
    getUserPermissions {
      permissions
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetRoles($rolesInput: GetRolesInput!) {
    getRoles(rolesInput: $rolesInput) {
      roles {
        id
        name
        permissions {
          id
          description
        }
      }
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query {
    getAllPermissions {
      permissions {
        id
        description
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole($createRoleInput: CreateRoleRequest!) {
    createRole(createRoleInput: $createRoleInput) {
      id
    }
  }
`;

export const GET_ROLE_BY_ID = (roleId: number) => gql`
  query {
    getRoleById(roleId: ${roleId}){
      id
      name
      permissions {
        id
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($roleInput: UpdateRoleRequest!) {
    updateRole(roleInput: $roleInput) {
      success
    }
  }
`;

export const REJECT_CUSTOMER_LOAN = gql`
  mutation RejectCustomerLoan($loanId: Int!) {
    rejectCustomerLoan(loanId: $loanId) {
      success
    }
  }
`;

export const REJECT_BUSINESS_LOAN = gql`
  mutation RejectBusinessLoan($loanId: Int!) {
    rejectBusinessLoan(loanId: $loanId) {
      success
    }
  }
`;

export const ACCEPT_CUSTOMER_LOAN = gql`
  mutation AcceptCustomerLoan($loanInput: AcceptCustomerLoanRequest!) {
    acceptCustomerLoan(loanInput: $loanInput) {
      success
    }
  }
`;
export const GET_CUSTOMER_LOAN_INSTALLMENTS = (loanId: number) => gql`
  query {
    getCustomerLoanInstallments(loanId: ${loanId}) {
      id
      graceDays
      principalAmount
      interestAmount
      paidInterestAmount
      paidPrincipalAmount
      dueDate
      status
    }
  }
`;
export const ACCEPT_BUSINESS_LOAN = gql`
  mutation AcceptBusinessLoan($loanInput: AcceptBusinessLoanRequest!) {
    acceptBusinessLoan(loanInput: $loanInput) {
      success
    }
  }
`;

export const GET_BUSINESS_LOAN_INSTALLMENTS = (loanId: number) => gql`
  query {
    getBusinessLoanInstallments(loanId: ${loanId}) {
      id
      graceDays
      principalAmount
      interestAmount
      paidInterestAmount
      paidPrincipalAmount
      dueDate
      status
    }
  }
`;

export const CREATE_CUSTOMER_INSTALLMENT_PAYMENT = gql`
  mutation CreateCustomerInstallmentPayment($installmentInput: CustomerLoanPaymentRequest!) {
    createCustomerInstallmentPayment(installmentInput: $installmentInput) {
      success
    }
  }
`;

export const CREATE_BUSINESS_INSTALLMENT_PAYMENT = gql`
  mutation CreateBusinessInstallmentPayment($installmentInput: BusinessLoanPaymentRequest!) {
    createBusinessInstallmentPayment(installmentInput: $installmentInput) {
      success
    }
  }
`;

export const GET_CUSTOMER_LOAN_PAYMENTS = (loanId: number) => gql`
  query{
    getCustomerLoanPayments(loanId: ${loanId}) {
      id
      principalAmount
      interestAmount
      createdAt
      updatedAt
    }
  }
`;

export const GET_BUSINESS_LOAN_PAYMENTS = (loanId: number) => gql`
  query {
    getBusinessLoanPayments(loanId: ${loanId}) {
      id
      principalAmount
      interestAmount
      createdAt
      updatedAt
    }
  }
`;
