import ModuleWrapper from '../../Components/ModuleWrapper';
import DataTable, { ITableColumn } from '../../Components/DataTable';
import StatusButton from '../../Components/StatusButton';
import { ActionListProps } from '../../Components/ActionList';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ReactComponent as DotsIcons } from '../../Assets/Images/Dots.svg';
import { useFetchJobs } from '../Jobs/use-fetch-jobs';
import { toYYMMdd } from '../../utils/date';
import ExecutionModal from './executions-modal';
import { useFetchJobExecutions } from './use-fetch-executions';

export default function JobItem() {
  const navigate = useNavigate();
  const params = useParams();
  const jobId = params.jobId;
  const { fetchExecutions, executionItems, loading } = useFetchJobExecutions();

  useEffect(() => {
    fetchExecutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tableColumns: ITableColumn[] = [
    {
      name: 'Date',
      dataKey: 'date',
    },
    {
      name: 'Current step',
      dataKey: 'currentStep',
    },

    {
      name: 'Action',
      dataKey: 'action',
    },
  ];

  console.log('Execution items ', executionItems);

  const tableData = executionItems.map((item, index: number) => ({
    ...item,
    action: <ExecutionModal execution={item} />,
  }));

  return (
    <ModuleWrapper>
      <h2 className="font-gilroy font-bold text-h3-small text-page-header">
        Monitoring Tasks Executions
      </h2>
      <div className="flex w-full mt-6.5 pb-4">
        <DataTable
          columns={tableColumns}
          data={tableData}
          header="Monitoring Tasks Executions"
          loading={loading}
        />
      </div>
    </ModuleWrapper>
  );
}
