import { useEffect, useState } from 'react';
import clsx from 'clsx';
import CreateButton from '../../Components/CreateButton';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { IconButton, Paper } from '@mui/material';
import { ReactComponent as EditorIcon } from '../../Assets/Images/editor.svg';
import CheckBox from './StyledCheckBox';
import { GET_ALL_ROLES, client } from '../../client';
import { Outlet, useNavigate } from 'react-router-dom';

export interface IRole {
  id: number;
  name: string;
  permissions: {
    id: number;
    description: string;
  }[];
}

export default function Roles() {
  const [openedItems, setOpenedItems] = useState<Set<number>>(new Set());
  const [roles, setRoles] = useState<IRole[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    client
      .query({
        query: GET_ALL_ROLES,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          rolesInput: {},
        },
      })
      .then(result => {
        if (result.errors) {
        }
        if (result.data) {
          const roles: IRole[] = (result?.data?.getRoles?.roles || []).map((role: any) => ({
            id: role.id,
            name: role.name,
            permissions: (role?.permissions || []).map((permission: any) => ({
              id: permission.id,
              description: permission.description,
            })),
          }));
          setRoles(roles);
        }
      });
  }, []);

  function onTabCollapse(id: number) {
    const items = new Set(openedItems);
    if (items.has(id)) {
      items.delete(id);
      setOpenedItems(items);
    } else {
      items.add(id);
      setOpenedItems(items);
    }
  }
  return (
    <div className="flex flex-1 flex-col">
      <Paper className="w-full !shadow-paper pt-3 pl-5.5 pr-6 pb-5.5 ">
        <div className="flex flex-row justify-between items-center mb-2.5">
          <h3 className="text-xl font-semibold text-header">Roles</h3>
          <CreateButton
            label="Add Role"
            onHandleClick={() => {
              navigate('/admin-panel/roles/create');
            }}
          />
        </div>
        <div className="flex flex-col gap-5.25">
          {roles.map(role => {
            const isOpen = openedItems.has(role.id);
            return (
              <div
                key={role.id}
                className={clsx(
                  'flex flex-col gap-3.75 pb-2 border-b-0.4 border-solid border-gray-4',
                  {
                    'bg-cell rounded-br-md8 rounded-bl-md8 border-b-3 border-secondary/32 pt-4.75 pl-3.25 pb-5 pr-4.25':
                      isOpen,
                  }
                )}
              >
                <div className="flex flex-row justify-between items-start">
                  <div className="flex flex-col gap-0.5">
                    <h3
                      className={clsx('text-lg font-medium text-sider capitalize', {
                        'font-semibold !text-blue': isOpen,
                      })}
                    >
                      {role.name}
                    </h3>
                    <h6 className="font-normal text-placeholder text-base ">Role description</h6>
                  </div>
                  {!isOpen ? (
                    <IconButton onClick={() => onTabCollapse(role.id)}>
                      <ChevronRight className="text-sider" />
                    </IconButton>
                  ) : (
                    <div className="flex flex-row items-center gap-2.75">
                      <IconButton
                        onClick={() => navigate(`/admin-panel/roles/edit?roleId=${role.id}`)}
                      >
                        <EditorIcon />
                      </IconButton>
                      <IconButton onClick={() => onTabCollapse(role.id)}>
                        <KeyboardArrowDown className="text-blue" />
                      </IconButton>
                    </div>
                  )}
                </div>
                {isOpen && (
                  <div className="flex flex-col gap-3.25 pr-2.5">
                    {role.permissions.map(permission => (
                      <div key={permission.id} className="flex flex-row items-center gap-4.25">
                        <CheckBox checked={true} />
                        <p className="text-sm font-normal text-placeholder first-letter:capitalize">
                          {permission.description}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Paper>
      <Outlet />
    </div>
  );
}
