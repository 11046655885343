import { Paper } from '@mui/material';

export default function Permissions() {
  return (
    <div className="flex flex-1 flex-col">
      <Paper className="w-full !shadow-paper pt-3 pl-5.5 pr-6 pb-5.5 ">
        <h1>Hello</h1>
      </Paper>
    </div>
  );
}
