import { useCallback, useState } from 'react';
import { usePagination } from '../../Common/hooks/use-pagination';
import { fetchJobsApi } from '../../rest_client';
import { Dayjs } from 'dayjs';

export type IJob = {
  id: string;
  title: string;
  job_type: string;
  description: string;
  source_system: string;
  start_time: string;
  end_time: string;
  frequency: string;
  job_count: number;
  executions?: any[];
};

export function useFetchJobs() {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IJob[]>([]);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const { setTotalItems, setTotalPages, totalItems, totalPages, page, limit, setPage } =
    usePagination();

  const fetchJobs = useCallback(async () => {
    try {
      setLoading(true);
      const bothDates = startDate && endDate;
      const startDateString = bothDates ? startDate?.format('YYYY-MM-DD') : undefined;
      const endDateString = bothDates ? endDate?.format('YYYY-MM-DD') : undefined;
      const action = (startDateString && endDateString) || title ? 'FILTER' : undefined;
      const response = await fetchJobsApi({
        limit: limit,
        offset: page - 1,
        startDate: startDateString,
        endDate: endDateString,
        title,
        action,
      });
      setLoading(false);
      if (response) {
        setTotalItems(response?.count ?? 0);
        setTotalPages(response?.count ? Math.ceil(response.count / limit) : 0);
        const radios = response?.results?.map((item: any): IJob => {
          return {
            id: item.uid,
            title: item?.title?.replace(/_/g, ' '),
            job_type: item?.job_type?.replace('_', ' '),
            description: item.description,
            source_system: item?.source_system?.replace('_', ' ') ?? 'MWENDO SPEECH',
            start_time: item.start_time,
            end_time: item.end_time,
            frequency: item.repetition_frequency,
            job_count: item?.periodic_task?.executions?.length || 0,
            executions: item?.periodic_task?.executions,
          };
        });
        setJobs(radios);
      } else {
        setIsErrored(true);
      }
    } catch (error) {
      setLoading(false);
      setIsErrored(true);
      console.log(error);
    }
  }, [limit, page, title, startDate, endDate]);

  return {
    fetchJobs,
    loading,
    jobs,
    isErrored,
    totalItems,
    totalPages,
    page,
    setPage,
    limit,
    setEndDate,
    setStartDate,
    setTitle,
  };
}
